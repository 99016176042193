@import "../../../assets/scss/variables";

.filter-countries {
  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-menu {
    height: 360px;
    width: 290px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    column-gap: 12px;
    color: $white;

    &:hover, &.selected {
      background-color: rgba(255, 255, 255, 0.05)
    }
  }
}
