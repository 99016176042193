@import "src/assets/scss/variables";

.match-odds {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: $dark-alt;
  width: 100%;
  border-radius: 0.25rem;
  padding: 0.5rem;

  &:hover {
    opacity: 0.9;
  }

  .odds {
    color: $betway;
    font-size: 0.9rem;
    font-weight: 700;
  }

  .partner {
    img {
      width: 100%;
      height: 30px;
    }
  }
}