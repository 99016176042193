@import 'src/assets/scss/variables';

.btn-checkbox {
  display: flex;
  align-items: center;
  column-gap: 8px;
  white-space: nowrap;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: #42464B;
  }

  &.checked {

  }
}
