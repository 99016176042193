.filters {
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  & > * {
    padding: 9px 16px;
  }

  &.games > * {
    width: 56px;
    min-width: 56px;
    height: 56px;
    padding: 8px;

    img {
      height: 40px;
      width: 40px;
    }
  }
}
