@import "../../../assets/scss/variables";

.leaderboard-event {
  .hero {
    height: 400px;
    width: 100%;
    background-image: url("~/public/images/bg/leaderboard-hero.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: hidden;

    .hero-info {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 46px;
      height: 100%;
      z-index: 1;

      h1 {
        font-family: "IperionW00", sans-serif;
        font-weight: 400;
        font-size: 40px;
        color: $white;
      }

      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        color: $secondary-white;
      }

      h5 {
        font-weight: 400;
        font-size: 24px;
        color: $secondary;
        span {
          color: #80BAD6;
        }
      }

      .divider {
        border: 0;
        height: 1px;
        margin: 10px 0 15px;
        background: linear-gradient(90deg, #80BAD6 2.61%, rgba(128, 186, 214, 0) 100%);
        width: 100%;
      }

      .btn-terms {
        display: flex;
        align-items: center;
        column-gap: 8px;
        color: $white;
        font-size: 14px;
        cursor: pointer;
        margin-right: auto;
        margin-top: 40px;

        svg {
          height: 40px;
          width: 40px;
          background: rgba(255, 255, 255, 0.05);
          border-radius: 100%;
          padding: 10px;
        }
      }
    }

    .hero-image {
      position: absolute;
      width: 55%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      right: 0;
      top: 0;
      opacity: .7;
      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }

      img {
        height: inherit;
      }
    }
  }

  .time-prize-card {
    background: $card-bg;
    border: 1px solid rgba(255, 255, 255, 0.05);
    display: flex;
    height: 167px;
    overflow: hidden;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35%;
      background: #0F1628;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 15px;
        right: -8px;
        top: -10px;
        background: linear-gradient(#1E2537, $card-bg);
        transform: rotate(5deg);
        bottom: -10px;
      }
      &:before {
        content: '';
        position: absolute;
        right: 7px;
        top: 0;
        bottom: 0;
        background: linear-gradient(183.61deg, #A9A9A9 0.91%, rgba(169, 169, 169, 0) 97.19%);
        width: 1px;
        transform: rotate(5deg);
      }
    }

    img, svg {
      width: 30px;
      height: 35px;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 8px;
      flex: 1;

      .text {
        font-weight: 700;
        font-size: 32px;
        color: $white;
      }

      .subtext {
        color: $secondary;
        font-weight: 400;
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }

  .leaders-race {
    position: relative;
    padding: 30px;
    background: $card-bg;

    &:before {
      position: absolute;
      opacity: .3;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url("~/public/images/bg/leaderboard-race.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    &:after {
      position: absolute;
      content: '';
      background-image: url("~/public/images/bg/finish.svg");
      top: 30px;
      right: 30px;
      bottom: 30px;
      width: 13px;
      background-position: center;
      background-repeat: repeat;
    }

    .race-row {
      display: flex;
      align-items: center;
      position: relative;

      .user {
        position: relative;
        display: flex;
        column-gap: 8px;
        align-items: center;
        background: $midnight-bg;
        width: 200px;
        padding: 10px;
        margin: 8px 0;

        &:after {
          position: absolute;
          content: '';
          top: -8px;
          right: 0;
          bottom: -8px;
          border-right: 1px solid #3B697F;
        }

        .place {
          width: 32px;
          height: 32px;
          background: rgba(255, 255, 255, 0.05);
          display: flex;
          align-items: center;
          justify-content: center;
          color: $secondary-white;
        }
        .info {
          width: 100px;
          font-size: 14px;
          color: $midnight-accent;
          line-height: normal;
        }
      }

      .progress {
        flex: 1;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 0 6px 6px 0;
        margin-right: 12px;
        height: 10px;
        overflow: initial;

        .progress-bar {
          background: $midnight-accent;
          position: relative;
          overflow: initial;

          &:after {
            position: absolute;
            content: '';
            background-image: url("~/public/images/bg/rocket.svg");
            top: -40px;
            right: -13px;
            width: 90px;
            height: 90px;
            background-position: center;
            background-repeat: repeat;
            background-size: cover;
            z-index: 1;
          }
        }
      }
    }
  }

  .winners-places {
    position: relative;
    display: flex;
    justify-content: center;
    column-gap: 24px;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      top: -20%;
      bottom: -20%;
      background-image: url("~/public/images/bg/dots.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .place {
      width: 25%;
      position: relative;
      box-shadow: inset 0 0 26px rgba(255, 255, 255, 0.05);
      overflow: hidden;
      padding: 16px;
      display: flex;
      align-items: center;
      flex-direction: column;

      &:before {
        position: absolute;
        content: '';
        bottom: -49px;
        left: -134px;
        width: 100%;
        height: 250px;
        background: $midnight-bg;
        transform-origin: 0 100%;
        transform: rotate(70deg) skewY(-50deg);
        box-shadow: 0 0 26px rgba(255, 255, 255, 0.05);
      }

      .position {
        font-weight: 700;
        margin-bottom: 6px;
        font-size: 24px;

        &:before {
          position: absolute;
          content: '';
          top: 0;
          right: 0;
          width: 100%;
          height: 2px;
        }

        :after {
          position: absolute;
          content: '';
          top: 0;
          right: 0;
          width: 2px;
          height: 100%;
        }
      }

      .avatar {
        border: 2px solid;
        border-radius: 4px;
        width: 72px;
        height: 72px;
        padding: 4px;
        margin-bottom: 6px;

        & > img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }

      &.place-1 {
        height: 312px;
        color: #F2C94C;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.2) 15.38%, rgba(0, 0, 0, 0) 96.15%), #151E35;

        :first-child:before, :first-child:after {
          background: linear-gradient(219.89deg, #F2C94C 0%, rgba(242, 201, 76, 0) 37.92%);
        }

        .position {
          font-size: 45px;
        }
      }
      &.place-2 {
        height: 254px;
        color: #BDBDBD;
        background: $midnight-bg;

        :first-child:before, :first-child:after {
          background: linear-gradient(225.64deg, #BDBDBD 0%, rgba(189, 189, 189, 0) 36.23%);
        }
      }
      &.place-3 {
        height: 232px;
        color: #D8A366;
        background: $midnight-bg;
        :first-child:before, :first-child:after {
          background: linear-gradient(222.73deg, #D8A366 0%, rgba(216, 163, 102, 0) 39.45%);
        }
      }
    }

  }
}
