@import "src/assets/scss/variables";

.match-widget {
  background-color: $dark-blue;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: #1B2132;
    height: 60px;
    padding: 18px 24px;
    color: $secondary;

    > * {
      z-index: 2;
    }

    .bg-after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow: hidden;
      z-index: 1;

      &:after {
        position: absolute;
        content: '';
        background-color: #27324A;
        width: 35%;
        top: -1000px;
        right: 0;
        bottom: -1000px;
        transform: rotate(30deg);
      }
    }
  }

  .stream {
    padding: 24px 12px 24px 24px;
  }

  .match {
    padding: 24px 24px 24px 12px;
    display: flex;
    column-gap: 24px;
  }

  .card-body {
    padding: 0 24px 24px;
    background-color: $card-bg;
  }
}
