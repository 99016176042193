@import "src/assets/scss/root";
@import "src/assets/scss/variables";
@import "src/assets/scss/container";

@font-face {
  font-family: "Iperion W00";
  src: url("./assets/fonts/3ef3a10ce0c9be98816b2ea7896a35e4.eot"); /* IE9*/
  src: url("./assets/fonts/3ef3a10ce0c9be98816b2ea7896a35e4.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./assets/fonts/3ef3a10ce0c9be98816b2ea7896a35e4.woff2") format("woff2"), /* chrome、firefox */
  url("./assets/fonts/3ef3a10ce0c9be98816b2ea7896a35e4.woff") format("woff"), /* chrome、firefox */
  url("./assets/fonts/3ef3a10ce0c9be98816b2ea7896a35e4.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("./assets/fonts/3ef3a10ce0c9be98816b2ea7896a35e4.svg#Iperion W00") format("svg"); /* iOS 4.1- */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a:focus-visible {
  outline: 0;
}

body {
  font-family: "Space Grotesk", sans-serif !important;
}

footer {
  min-height: 196px;
}

.page {
  padding-top: $navHeight;
  min-height: calc(100vh - 196px);

  @include media-breakpoint-down(xxl) {
    padding-top: $navSmallHeight;
  }
}

.side-widget {
  background-color: rgba(255, 255, 255, 0.05);
}

h3 {
  font-weight: 700;
  color: #F2F2F2;
}

.btn {
  padding: 8px 24px;
  border-radius: 0 !important;
}

.btn-midnight {
  position: relative;
  color: $white !important;
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.15) !important;

  &.hovered {
    background-color: rgba(255, 255, 255, 0.10) !important;
  }

  &.selected, &.active {
    background-color: rgba(255, 255, 255, 0.10) !important;

    &:before, &:after {
      position: absolute;
      top: -1px;
      bottom: -1px;
      width: 8px;
      border: 2px solid $midnight-accent;
      content: "";
    }

    &:before {
      left: -1px;
      border-right-width: 0;
    }

    &:after {
      right: -1px;
      border-left-width: 0;
    }
  }

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  &:hover {
    color: $white !important;
    background-color: rgba(255, 255, 255, 0.15) !important;
  }

  &[disabled] {
    opacity: .3;
  }
}

.side {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.side-widget {
  display: flex;
  flex-direction: column;
}

.btn-blue-opaque {
  background-color: rgba(70, 226, 236, 0.1) !important;
  border-color: $midnight-accent !important;
  color: $midnight-accent !important;

  &:hover {
    background-color: $midnight-accent !important;
    color: white !important;
  }
}

.btn-white-opaque {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: white !important;
  color: white !important;

  &:hover {
    background-color: white !important;
    color: $midnight-accent !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-none {
  cursor: default !important;
}

h3 {
  font-size: 1.5rem;
}

.no-border {
  border: 0;
}

.large-card {
  border: 0;
  border-radius: 0;
  background-color: $card-bg !important;

  @include media-breakpoint-down(lg) {
    &:not(.always) {
      background-color: transparent !important;
    }
  }

  .card-header {
    background-color: $card-bg;
    color: $secondary-white;
    padding: 24px;
    border: 0;
    border-radius: 0;
  }

  .card-footer {
    background-color: $card-bg;
    padding: 24px;
    border: 0;
    border-radius: 0;
    text-align: center;

    @include media-breakpoint-down(lg) {
      margin-top: 20px;
    }
  }

  .card-body {
    padding: 24px;
    background-color: $bg-dark;
    color: $secondary-white;

    &.active {
      background-color: #223342;
    }

    &.disabled > * {
      opacity: .25;
      pointer-events: none;
    }

    &.focus {
      box-shadow: 0 0 5px 3px $midnight-accent;
    }
  }

  .card-title {
    font-size: 14px;
  }

  .card-text {
    font-size: 10px;
    color: $secondary;
  }

  .card-progress {
    span {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      font-size: 8px;
      color: $secondary;
    }

    .progress {
      height: 8px;
      background-color: #323B46;
      width: 100%;
    }

    .progress-bar {
      background-color: $midnight-accent;
    }

    &.inline {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-bottom: -10px;

      span {
        font-size: 10px;
        color: $midnight-accent;
      }
    }
  }

  .card-divider {
    margin: 8px 0;
    height: 1px;
    background-color: #343E54;

    &.full {
      margin: 8px -24px;
    }
  }

  .card-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .diamonds {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 4px;

      img {
        height: 15px;
      }

      span {
        font-size: 10px;
      }
    }
  }

  .card-actions {
    font-size: 12px;
    color: $secondary;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
    justify-content: space-between;
    overflow: hidden;
  }

  .btn-like {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:not(.cursor-none):hover img {
      opacity: 1;
    }

    img {
      height: 16px;
      margin-right: 4px;
      opacity: .3;
    }

    &.active img {
      opacity: 1;
    }
  }

  a {
    display: flex;
    justify-content: center;
    color: $midnight-accent;
    text-decoration-line: underline;
    font-size: 12px;
  }

  .split-cell {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -24px;
      left: 0;
      bottom: -8px;
      background-color: #343E54;
      width: 1px;
    }
    &:after {
      content: '';
      position: absolute;
      top: -24px;
      right: 0;
      bottom: -8px;
      background-color: #343E54;
      width: 1px;
    }
  }
}

.btn-outline-primary {
  @include button-outline-variant(
    $midnight-accent,
    $midnight-accent,
    rgba(70, 226, 236, 0.25),
    lighten($midnight-accent, 10%),
    $midnight-accent
  );

  background-color: rgba(70, 226, 236, 0.1);

  &.small {
    padding: 4px 8px;
    font-size: 12px;
  }
}

.btn-outline-secondary {
  @include button-outline-variant(
    $secondary-white,
    $secondary-white,
    rgba(255, 255, 255, 0.2),
    lighten($secondary-white, 10%),
    $secondary-white
  );

  background-color: rgba(255, 255, 255, 0.05);

  &.small {
    padding: 4px 8px;
    font-size: 12px;
  }
}

.show-grid {
  .row+.row {
    margin-top: 1rem;
  }
}

.main-row {
  padding: 12px;
  background-color: $bg-dark;
  color: $secondary-white;
  margin: 0 auto 8px;
  width: 100%;

  .split-right, .split-left {
    position: relative;

    @include media-breakpoint-down(xl) {
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        background-color: #283142;
        height: 1px;
      }
      &:after {
        content: '';
        position: absolute;
        top: -12px;
        bottom: 0;
        background-color: #283142;
        width: 1px;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .split-right:after {
      right: 0;
    }
    .split-right:before {
      right: 0;
      left: -12px;
    }
    .split-left:after {
      left: 0;
    }
    .split-left:before {
      right: -12px;
      left: 0;
    }
  }
}

.accordion {

  .accordion-item {
    background-color: transparent;

    @include media-breakpoint-up(lg) {
      .collapse {
        display: block;
      }
    }
  }

  .accordion-collapse {
    margin-top: 20px;
  }

  .accordion-button {
    background-color: #262B3A;
    color: $white;
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    padding: 27px 16px;

    &:focus {
      box-shadow: none;
      border: 0;
    }

    &:after {
      position: absolute;
      right: 24px;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    }

    &:before {
      content: "";
      position: absolute;
      right: 16px;
      width: 36px;
      height: 36px;
      background-color: #353A48;
    }
  }

  .accordion-body {
    padding: 0;
  }
}

.modal-dialog {
  &.max-width-medium {
    max-width: 700px;
  }

  &.max-width-large {
    max-width: 960px;
  }

  .modal-content {
    background: #192031;

    .modal-body {
      max-height: 400px;
      overflow: auto;
    }

    .modal-header {
      color: $white;
      border: none;

      .btn-close {
        color: $white !important;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .modal-footer {
      border: none;
    }
  }
}

.nav-tabs {
  border: 0;
  column-gap: 8px;
  row-gap: 8px;

  .nav-link {
    @extend .btn-midnight;
    padding: 16px;
  }

  &.full-width {
    width: 100%;

    .nav-item {
      flex-grow: 1;
      flex-basis: 0;
    }

    .nav-link {
      width: 100%;
      white-space: nowrap;
    }

    @include media-breakpoint-up(md) {
      &.full-width-md-none {
        width: auto;
      }
    }
  }
}

.custom-progress {
  display: flex;
  align-items: center;
  column-gap: 8px;
  min-width: 140px;

  span {
    font-size: 14px;
    font-weight: 700;
    color: $midnight-accent;
    min-width: 40px;
  }

  .progress {
    height: 8px;
    width: 100%;
    background-color: #323B46;
  }

  .progress-bar {
    background-color: $midnight-accent;
  }
}

.score {
  font-family: "IperionW00", sans-serif;
  font-size: 1.5rem;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
}

.status {
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 4px;
  width: fit-content;
  text-transform: uppercase;
  white-space: nowrap;
  color: $secondary-white;
  border: 1px solid $secondary-white;

  &.green {
    color: $dark-green;
    border: 1px solid $dark-green;
  }

  &.red {
    color: $dark-red;
    border: 1px solid $dark-red;
  }
}

.form-control {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.9);
  border-radius: 0;
  color: $secondary-white;

  &:focus:not([readonly]) {
    color: $secondary-white;
    background-color: rgba(255, 255, 255, 0.02);
    border-color: inherit;
    box-shadow: none;
  }

  &[readonly] {
    color: $secondary-white;
    background-color: rgba(255, 255, 255, 0.2);
    border-color: inherit;
    box-shadow: none;
  }

}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.img-smallest {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.img-small {
  width: 32px;
  min-width: 32px;
  height: 32px;
}

.img {
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.img-medium {
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.img-large {
  width: 64px;
  min-width: 64px;
  height: 64px;
}

.img-largest {
  width: 80px;
  min-width: 80px;
  height: 80px;
}

.img-uploader {
  position: relative;
  &:hover {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.2) url("~/public/images/bg/image-upload.svg") no-repeat center center;
      background-size: 30px;
      cursor: pointer;
    }
  }
}

.fs-smallest {
  font-size: 10px !important;
}

.fs-small {
  font-size: 12px !important;
}

.fs-medium {
  font-size: 14px !important;
}

.fs-normal {
  font-size: 16px !important;
}

.fs-large {
  font-size: 24px !important;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.divider {
  height: 0;
  width: 100%;
  margin: 0.5rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.05)
}

.text-midnight {
  color: $midnight-accent !important;
}

.btn-slip-winner {
  padding: 10px;
  width: 100%;
  background: rgba(70, 156, 236, 0.1);
  border-radius: 4px;
  font-size: 14px;
  color: $secondary-white;
  text-align: center;
}

.dropdown-menu {
  background-color: #283142;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: rgba(255,255,255,.15) !important;
}

.after-content-none::after {
  content: none;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.border-midnight {
  border-color: $midnight-accent !important;
}

.border-transparent {
  border-color: transparent !important;
}

.border-lightest {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.rank {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  background: #242B3B;
  color: $secondary;

  &.lg {
    width: 64px;
    height: 64px;
  }

  &.rank-1 {
    background: #F2C94C;
    color: #16171B;
  }

  &.rank-2 {
    background: #BDBDBD;
    color: #16171B;
  }

  &.rank-3 {
    background: #D8A366;
    color: #16171B;
  }
}

.rank-content {
  background: #242B3B;
  color: $secondary;

  &.rank-1 {
    color: #F2C94C;
  }

  &.rank-2 {
    color: #BDBDBD;
  }

  &.rank-3 {
    color: #D8A366;
  }
}

.more-btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05);
  padding: 4px;
  color: $white;
  cursor: pointer;
}

.form-label {
  color: $white;
}

.form-select {
  color: $white;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23f2f2f2%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");

  &:disabled {
    background-color: #737a85;
    color: #b7bbc0;
  }
}

.max-height-none {
  max-height: none !important;
}

.max-height-75 {
  max-height: 75vh !important;
}

.list-group-numbered>li::before {
  min-width: 15px;
  font-size: 14px;
}
