@import "../../assets/scss/variables";

.profile-main-section {
  height: 250px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: calc(40% + 85px);
    background: #0F1628;
    transform: rotate(15deg);
    top: -100px;
    bottom: -100px;
    left: -100px;
  }

  .top-bottom {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      width: 57%;
      height: 5px;
      border-left: 10px solid transparent;
      border-top: 5px solid $midnight-accent;
      z-index: 1;
    }

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 20%;
      height: 5px;
      border-right: 10px solid  transparent;
      border-bottom: 5px solid $midnight-accent;
      z-index: 1;
    }
  }

  .left-section {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 32px;
    position: relative;
    width: 40%;
    padding: 50px;
    z-index: 1;

    &:after {
      position: absolute;
      content: '';
      width: 100px;
      right: -95px;
      top: -30px;
      background: url("~/public/images/bg/dots2.png") center center no-repeat;
      background-size: cover;
      transform: rotate(15deg);
      bottom: -30px;
    }
    &:before {
      content: '';
      position: absolute;
      right: 7px;
      top: -10px;
      bottom: -10px;
      background: linear-gradient(183.61deg, #a9a9a96e, rgba(169, 169, 169, 0) 97.19%);
      width: 1px;
      transform: rotate(15deg);
    }

    .avatar {
      position: relative;
      background: url("~/public/images/bg/avatar-border.svg") center center no-repeat;
      border: 1px solid rgba(255, 255, 255, 0.1);

      img {
        width: 118px;
        height: 118px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;

      .name {
        font-weight: 700;
        font-size: 24px;
        color: $white;
      }
      .date {
        color: $secondary;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        column-gap: 6px;
      }
    }

    @include media-breakpoint-down(md) {
      column-gap: 16px;
      justify-content: start;
      padding: 10px;

      .avatar img {
        width: 60px;
        height: 60px;
      }
      .info .name {
        font-size: 18px;
      }
      .info .date {
        font-size: 12px;
      }
    }
  }

  .right-section {
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
    padding: 50px;
    z-index: 1;

    .btn-follow {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      cursor: pointer;

      span:first-child {
        color: $secondary;
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
      }
      span:last-child {
        color: $white;
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
  }
}

.profile-stats-card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  width: 100%;

  .icon {
    background: #0F1628;
    min-height: 94px;
    width: 94px;
    min-width: 94px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      width: 30px;
    }
  }

  .content {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    column-gap: 4px;

    .title {
      font-weight: 400;
      font-size: 14px;
      color: $secondary;
      text-transform: capitalize;
    }

    .statistic {
      font-weight: 700;
      font-size: 24px;
      color: $white;
    }
  }
}

.profile-game-stats-card {
  position: relative;
  display: flex;
  width: 100%;
  height: 180px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;

  > * {
    position: relative;
    flex: 1;

    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(183.61deg, #a9a9a96e, rgba(169, 169, 169, 0) 97.19%);
        width: 1px;
      }
    }

  }
}

.profile-prediction-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $white;
  background: #1F2638;
  padding: 25px 20px;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .team-info {
    display: flex;
    align-items: center;
    column-gap: 8px;

    > img {
      width: 40px;
      min-width: 40px;
      height: 40px;
    }
  }

}
