:root {
  --bs-dark-rgb: 11,16,29 !important;
  --bs-body-bg: #0D1426 !important;
  --bs-secondary-rgb: 169,169,169 !important;
  --bs-primary: #80BAD6 !important;
}

.table-dark {
  --bs-table-bg: #1A2030 !important;
  --bs-table-hover-bg: #1F2638 !important;
  --bs-table-striped-bg: #1F2638 !important;
  --bs-table-active-bg: #1F2638 !important;
}

.th-dark {
  padding: 9px 0 9px 16px !important;
  background: #242B3B !important;
  color: #A9A9A9 !important;
}

.td-dark {
  padding: 4px 0 4px 16px !important;
  background: #1E2639 !important;
  color: #F2F2F2 !important;
  border-bottom-width: 2px !important;
}
