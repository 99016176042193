@import "../../../assets/scss/variables";

.filter-tournament {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  row-gap: 8px;

  & > * {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 7px;
    column-gap: 4px;
    min-width: 48px;
    max-width: 170px;
    font-size: 12px;

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      height: 24px;
      width: 24px;
      border-radius: 50%;
    }

    &.more {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
