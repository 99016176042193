@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

$dark-blue: #202638;
$dark-blue-alt: #131331;
$dark: #34495e;
$dark-alt: #2c3e50;
$dark-alt-transparent: rgba(44, 62, 80, 0.8);
$dark-luck: #171425;
$gray: #808191;
$light-gray: #bdc3c7;
$light-gray-alt: rgb(220, 220, 220);
$lightest-gray: #ecf0f1;
$dark-grey: #95a5a6;
$darkest-grey: #7f8c8d;
$yellow: #ecb646;
$light-red: #e74c3c;
$dark-red: #c0392b;
$twitch: #6441a5;
$light-green: #2ecc71;
$dark-green: #27ae60;
$midnight-bg: #0D1426;
$midnight-accent: #80BAD6;
$secondary: #A9A9A9;

$bs-dark: rgb(11,16,29);
$bg-dark: #1F2638;

$navHeight: 120px;
$navSmallHeight: 82px;
$navSmallBgColor: #232C43;

$secondary-white: #F2F2F2;
$betway: #D8B0FF;

$card-bg: #1A2030;
