.accordion-match-map {
  .accordion-collapse {
    margin-top: 0;
  }

  .collapse:not(.show) {
    display: none;
  }

  .accordion-button {
    display: flex;
    justify-content: space-between;
    padding-left: 44px;
    background-color: rgba(255, 255, 255, 0.1) !important;

    &:before {
      content: none;
    }
    &:after {
      width: 20px;
      height: 20px;
      left: 16px;
      right: auto;
    }
  }

  .team-row {
    padding: 20px;

    .placeholder {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &:not(:first-child) {
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }
  }
}
