@import "../../../assets/scss/variables";

.marketplace-item {
  position: relative;
  background: $card-bg;
  color: $white;

  & > * {
    padding: 0;
    z-index: 1;
  }

  &:before {
    position: absolute;
    content: '';
    width: 9px;
    left: 0;
    top: 0;
    bottom: 0;
    border: 1px solid;
    border-right: 0;
  }

  &:after {
    position: absolute;
    content: '';
    width: 9px;
    right: 0;
    top: 0;
    bottom: 0;
    border: 1px solid;
    border-left: 0;
  }

  &.marketplace-card {
    background: $card-bg linear-gradient(270deg, #c4c4c44d -0.56%, rgba(196, 196, 196, 0) 66.85%);
    min-height: 240px;
    margin: 30px 0 0;

    .title {
      padding: 50px;
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      column-gap: 50px;
      row-gap: 50px;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        top: -15px;
        left: 0;
        bottom: 30px;
        background-image: url("~/public/images/bg/marketplace-mask.svg");
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        width: 80%;
      }

      @include media-breakpoint-down(md) {
        justify-content: center;
      }
    }

    &.uncommon {
      background: $card-bg linear-gradient(270deg, #80d6a84d -0.56%, rgba(128, 214, 168, 0) 66.85%);
    }

    &.rare {
      background: $card-bg linear-gradient(270deg, #80a8d64d -0.56%, rgba(128, 168, 214, 0) 66.85%);
    }

    &.epic {
      background: $card-bg linear-gradient(270deg, #8180d64d -0.56%, rgba(130, 128, 214, 0) 66.85%);
    }

    &.legendary {
      background: $card-bg linear-gradient(270deg, #d6d3804d -0.56%, rgba(214, 211, 128, 0) 66.85%);
    }

    .item:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &.common {
    &:before, &:after {
      border: 0;
    }
  }
  &.uncommon {
    color: #80D6A8;
    &:before, &:after {
      border-color: #80D6A8;
    }
  }
  &.rare {
    color: #80A8D6;
    &:before, &:after {
      border-color: #80A8D6;
    }
  }
  &.epic {
    color: #8280D6;
    &:before, &:after {
      border-color: #8280D6;
    }
  }
  &.legendary {
    color: #D6D380;
    &:before, &:after {
      border-color: #D6D380;
    }
  }

  .prise-image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-image: url("~/public/images/bg/marketplace.png");
    min-width: 340px;
    z-index: 1;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1px;
      background: linear-gradient(#A6A6A6, #3C404D);

      @include media-breakpoint-up(md) {
        content: '';
      }
    }

    &.circle {
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
      width: fit-content;
      margin: auto;
      &:before {
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: 5px;
        right: 5px;
        width: auto;
        background: radial-gradient(#c4c4c44d -66.85%, rgba(196, 196, 196, 0) 66.85%);
      }

      &.uncommon:before {
        background: radial-gradient(#80d6a84d -66.85%, rgba(128, 214, 168, 0) 66.85%);
      }

      &.rare:before {
        background: radial-gradient(#80a8d64d -66.85%, rgba(128, 168, 214, 0) 66.85%);
      }

      &.epic:before {
        background: radial-gradient(#8180d64d -66.85%, rgba(130, 128, 214, 0) 66.85%);
      }

      &.legendary:before {
        background: radial-gradient(#d6d380 -66.85%, rgba(214, 211, 128, 0) 66.85%);
      }

    }

    img {
      width: 224px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    column-gap: 8px;

    .chance {
      width: 35px;
      background: rgba(70, 156, 236, 0.1);
      border-radius: 4px;
      padding: 3px;
      text-align: center;
      color: $white;
      font-size: 12px;
    }

  }

  .rarity-borders {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    .left-right {
      position: relative;
      height: inherit;

      &:before {
        position: absolute;
        content: '';
        width: 5px;
        top: 30%;
        height: 40%;
        left: 0;
        border-left: 5px solid;
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
      }

      &:after {
        position: absolute;
        content: '';
        width: 5px;
        top: 30%;
        height: 40%;
        right: 0;
        border-right: 5px solid;
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
      }
    }

    .top-bottom {
      position: relative;
      height: inherit;

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 40%;
        width: 20%;
        height: 5px;
        border-left: 5px solid  transparent;
        border-right: 5px solid  transparent;
        border-top: 5px solid;
      }

      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 40%;
        width: 20%;
        height: 5px;
        border-left: 5px solid  transparent;
        border-right: 5px solid  transparent;
        border-bottom: 5px solid;
      }
    }

    &.legendary {
      .left-right:before, .left-right:after {
        top: 10%;
        height: 80%;
      }
      .top-bottom:before, .top-bottom:after {
        left: 5%;
        width: 90%;
      }
    }
  }

  .modal-winner {
    z-index: 0;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      -webkit-mask-image: url("~/public/images/bg/marketplace-winner.svg");
      -webkit-mask-position: center;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-size: cover;
      background-color: $white;
    }
    &.uncommon:before {
      background-color: #80d6a8;
    }
    &.rare:before {
      background-color: #80a8d6;
    }
    &.epic:before {
      background-color: #8180d6;
    }
    &.legendary:before {
      background-color: #d6d380;
    }
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
    height: 63px;
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
  }

  .modal-header {
    border-radius: 0;
    padding: 32px !important;
    color: inherit !important;

    & > * {
      margin: 0;
    }

    .btn-icon {
      opacity: 0;
      cursor: pointer;
      width: 40px;
      height: 40px;
      color: $white;
      z-index: 1;
    }
  }

  .stepper {
    display: flex;
    justify-content: center;
    column-gap: 12px;
    padding: 10px 0;

    & > * {
      width: 28px;
      height: 3px;
      background-color: $white;
      opacity: .3;
      cursor: pointer;
      z-index: 1;

      &.selected {
        opacity: 1;
      }
    }

  }

  .winner-card {
    background: rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 230px;
    height: 380px;
    backdrop-filter: blur(34px);
    margin: 20px 0;

    &:before {
      position: absolute;
      content: '';
      width: 9px;
      left: 0;
      top: 0;
      bottom: 0;
      border: 1px solid;
      border-right: 0;
    }

    &:after {
      position: absolute;
      content: '';
      width: 9px;
      right: 0;
      top: 0;
      bottom: 0;
      border: 1px solid;
      border-left: 0;
    }

    .img {
      width: 181px;
      height: 253px;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .top {
      position: relative;
      height: 38px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        text-transform: uppercase;
        z-index: 1;
      }

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        height: 38px;
        width: 100%;
        border-top: 38px solid;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
      }
    }

    .bottom {
      height: 49px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      color: $white;
      background: rgba(255, 255, 255, 0.1);
    }

    &.common .top span {
      color: $black;
    }

    &.uncommon .top span,
    &.rare .top span,
    &.epic .top span,
    &.legendary .top span, {
      color: $white;
    }

    &.uncommon {
      background: rgba(128, 214, 168, 0.4);
    }
    &.rare {
      background: rgba(128, 186, 214, 0.4);
    }
    &.epic {
      background: rgba(130, 128, 214, 0.4);
    }
    &.legendary {
      background: rgba(214, 211, 128, 0.4);
    }
  }

  .circle-mask {
    position: absolute;
    width: 300px;
    height: 300px;
    left: calc(50% - 150px);
    top: calc(50% - 200px);
    opacity: 0.7;
    filter: blur(100px);

    &.common {
      background: rgba(255, 255, 255, 0.4);
    }
    &.uncommon {
      background: rgba(128, 214, 168, 0.4);
    }
    &.rare {
      background: rgba(128, 186, 214, 0.4);
    }
    &.epic {
      background: rgba(130, 128, 214, 0.4);
    }
    &.legendary {
      background: rgba(214, 211, 128, 0.4);
    }
  }

  button {
    z-index: 1;
  }
}
