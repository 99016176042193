@import "../../../assets/scss/variables";

.leaderboards {
  color: $secondary;

  .row {
    display: flex;
    align-items: center;
    margin: 0 0 4px;
    background: rgba(255, 255, 255, 0.05);
    cursor: pointer;

    & > * {
      padding: 16px;
    }
  }

  .header {
    background: #242B3B;
  }
}
