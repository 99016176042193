@import "../../../assets/scss/variables";

.icon-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 100px;
  color: $white;
  cursor: pointer;

  & > svg {
    font-size: 20px;
  }
}
