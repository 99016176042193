@import "../../../assets/scss/variables";

.filter-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 14px;
  padding: 14px 18px;
  color: $white;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.15);

  .calendar {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
    white-space: nowrap;
    min-width: 90px;
    justify-content: space-around;
  }

  svg {
    font-size: 24px;
  }

  .btn-date {
    cursor: pointer;
  }
}
