@import "src/assets/scss/variables";

$toggleBtnWidth: 100px;

.navbar {
  height: $navHeight;
  padding: 0 !important;
  flex-wrap: nowrap;

  @include media-breakpoint-down(xxl) {
    height: $navSmallHeight;
  }

  .navbar-brand {
    img {
      height: 17px;
    }
  }

  .navbar-toggler {
    color: $secondary;
    border: 0;
    margin: 0 !important;

    &:focus {
      box-shadow: none;
    }
  }

  .navbar-menu {
    padding-left: 20px;
    z-index: 1;
    overflow: hidden;
    align-items: center;

    @include media-breakpoint-down(xxl) {
      padding-left: 20px;
      overflow: inherit;
    }

    .nav-link {
      text-transform: uppercase;
      padding: 9px 16px;
    }

    .nav-link.active {
      position: relative;
      color: $midnight-accent !important;

      &::before, &::after {
        position: absolute;
        top: -1px;
        bottom: -1px;
        width: 8px;
        border: 2px solid $midnight-accent;
        content: "";
      }
      &::before {
        left: -1px;
        border-right-width: 0;
      }
      &::after {
        right: -1px;
        border-left-width: 0;
      }
    }
  }

  .navbar-settings {
    position: relative;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0 20px 0 65px;

    &::before {
      content: '';
      position: absolute;
      top: -45px;
      left: -58px;
      transform: rotate(28deg);
      width: 0;
      height: 0;
      border-right: 100px solid $bs-dark;
      border-top: 150px solid $bs-dark;
    }

    @include media-breakpoint-down(xxl) {
      padding-left: 45px;
      &::before {
        top: -30px;
        left: -52px;
        border-right: 80px solid $bs-dark;
        border-top: 100px solid $bs-dark;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 0 0 0 10px;
      background-color: $navSmallBgColor;
      &::before {
        transform: rotate(10deg);
        top: -21px;
        left: -31px;
        border-right: 40px solid $bs-dark;
        border-top: 100px solid $bs-dark;
      }
    }
  }

  div[role="group"]:not(:first-child) {
    padding-left: 24px;
  }
}

.offcanvas[aria-labelledby="navbar"] {
  .nav-link {
    text-transform: uppercase;
    color: $secondary !important;
  }

  .nav-link.active {
    position: relative;
    color: $midnight-accent !important;
  }

  .navbar-btn-small {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 0;
    border: 0;
    color: $secondary;
    padding: 7px 10px;
  }

  .buttons-down  {
    display: flex;
    flex-direction: column;
    width: 100%;

    .btn-lang {
      padding: 10px;
    }

    .btn-missions {
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:focus {
        box-shadow: none;
      }

      a {
        display: flex;
        width: 36px;
        height: 36px;
        background: rgba(255, 255, 255, 0.07);
        transform: rotate(-90deg);
      }
    }
  }
}

.notifications {
  margin: auto;
  padding: 5px;

  .dropdown-toggle {
    position: relative;
    padding: 5px;

    &:after {
      content: none;
    }
  }

  .dropdown-menu {
    width: 300px;
    padding-bottom: 0;

    .scroll {
      max-height: 500px;
      overflow-y: auto;
    }

    .nav-link {
      font-size: 12px;
      padding: 5px 15px;
    }

    .dropdown-item {
      padding: 8px 16px;
      white-space: normal;
      color: $secondary;
      border-top: 1px solid #4e5562;

      &:hover, &:focus, &:active {
        background: inherit !important;
      }

      .badge {
        display: block;
        width: 7px;
        height: 7px;
        padding: 0;
      }

    }

  }

  .custom-badge {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 8px;
    height: 8px;
    background-color: $light-red;
    border-radius: 50%;
  }
}

.user-menu {
  line-height: 18px;

  img {
    width: 30px;
  }

  .name {
    font-size: 14px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      max-width: 160px;
    }
  }
  .email {
    font-size: 12px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.user-menu-small {
  margin: -16px -16px 16px -16px;
  padding: 10px 0;
  border-bottom: 1px solid #333B45;

  .btn-midnight {
    width: 100%;
    padding: 10px;
    text-transform: uppercase;

    &.selected {
      color: $midnight-accent;
    }
  }

  .account-details {
    text-transform: lowercase;
    color: $midnight-accent;
    font-size: 12px;

    img {
      transform: rotate(-90deg);
      width: 12px;
      height: 12px;
    }
  }

  .split {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 1px;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: #333B45;
      margin: -10px 0;
    }
  }
}
