@import "src/assets/scss/variables";

.aside {
  .offcanvas-title {
    img {
      height: 14px;
    }
  }

  .offcanvas-header {
    height: $navSmallHeight;

    .close-btn {
      position: relative;
      cursor: pointer;
      width: $navSmallHeight;
      background-color: $navSmallBgColor;
      height: calc(100% + 32px);
      margin: -16px;
      overflow: hidden;
      padding-left: 10px;

      &:before {
        content: '';
        position: absolute;
        transform: rotate(10deg);
        top: -21px;
        left: -31px;
        border-right: 40px solid $bs-dark;
        border-top: 100px solid $bs-dark;
      }
    }
  }

  .offcanvas-body {
    background-color: $midnight-bg;
  }
}
